import React from 'react';

const tableStyle = {
  marginTop:'30px',
  borderCollapse: 'collapse',
  width:'100%',
};

const tdSytle = {  
  borderStyle: 'solid',
  borderWidth: '0.1px',
  borderColor: 'black',
  fontFamily: 'Noto Sans JP',
  fontWeight: 400,
  fontSize: '14px',
  padding: '10px 5px 10px 5px',
  align: 'left',
};

function createData(item, contents) {
  return { item, contents};
}

const rows = [
  createData('Name', 'WENO & PARTNERS'),
  createData('Location', 'KS Iseya Building 8th floor, 3-21-23 Sakae, Naka-ku, Nagoya-shi, Aichi-ken, 460-0008 Japan'),
  createData('Chief', 'Motoharu YOSHIDA'),
  createData('Founded', 'April, 1991'),
  createData('Incorporation', 'May, 2015'),
  createData('Telephone', '+81-52-263-6871'),
  createData('Facsimille', '+81-52-263-6873'),
  createData('Alliance', (<>legal professional corporation PRO（<a href='https://i-l.info/' target='blank'>https://i-l.info/</a>）</>))
  // createData('Payments', (<><a href='https://www.weno-ip.jp/cc' target='blank'>Specified Commercial Transaction Act</a></>))
];

export default function Summary() {
  return (
    <table style={tableStyle}>
      <tbody>
        {rows.map((row) => (
          <tr>
            {//↓スプレッド構文（...）でtdSytleオブジェクトの内容を{{}}内に展開している
            }
            <td style={{...tdSytle, whiteSpace: 'nowrap'}}>{row.item}</td>
            <td style={tdSytle}>{row.contents}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
