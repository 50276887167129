import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarsIcon from '@material-ui/icons/Stars';
import WorkIcon from '@material-ui/icons/Work';
import BusinessIcon from '@material-ui/icons/Business';
import PeopleIcon from '@material-ui/icons/People';
import CommuteIcon from '@material-ui/icons/Commute';
import { Link } from 'react-scroll';

const useStyles = makeStyles({
  list: {
    width: 240,
  },
  fullList: {
    width: 'auto',
  },
});

const listStyle = {
}

const itemStyle = {
  height:'60px',
}

const iconStyle = {
  color:'black',
}

const textStyle = {
  fontFamily: 'Noto Sans JP',
  fontSize: '16px',
  fontWeight: 400,
  color: 'black',
}

export default function MenuDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)} 
    >
      <List style={listStyle}> 
        <Link to='profile' smooth={true} offset={-70}>
          <ListItem  button style={itemStyle}>
            <ListItemIcon>
              <StarsIcon style={iconStyle}/>
            </ListItemIcon>
            <ListItemText disableTypography style={textStyle}>
              Profile
            </ListItemText>
          </ListItem>
        </Link>

        <Link to='services' smooth={true} offset={-70}>
          <ListItem  button style={itemStyle}>
            <ListItemIcon>
              <WorkIcon style={iconStyle}/>
            </ListItemIcon>
            <ListItemText disableTypography style={textStyle}>
            Services
            </ListItemText>
          </ListItem>
        </Link>

        <Link to='attoneies' smooth={true} offset={-70}>
          <ListItem  button style={itemStyle}>
            <ListItemIcon>
              <PeopleIcon style={iconStyle}/>
            </ListItemIcon>
            <ListItemText disableTypography style={textStyle}>
            Professionals
            </ListItemText>
          </ListItem>
        </Link>
        
        <Link to='profiles' smooth={true} offset={-70}>
          <ListItem  button style={itemStyle}>
            <ListItemIcon>
              <BusinessIcon style={iconStyle}/>
            </ListItemIcon>
            <ListItemText disableTypography style={textStyle}>
            About
            </ListItemText>
          </ListItem>
        </Link>

        <Divider />

        <Link to='access' smooth={true} offset={-70}>
          <ListItem  button style={itemStyle}>
            <ListItemIcon>
              <CommuteIcon style={iconStyle}/>
            </ListItemIcon>
            <ListItemText disableTypography style={textStyle}>
            Access
            </ListItemText>
          </ListItem>
        </Link>
      </List> 
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={toggleDrawer(anchor, true)}><MenuIcon style={{color: 'black', fontSize:'24px'}} /></IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}