import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-scroll';
import Fab from '@material-ui/core/Fab';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: '0px',
    position: 'fixed',
    color:'black',
    backgroundColor: 'rgba(255,255,255,0.3)',
    //↓FABをスマホでタップしたときに背景色がグレーのままになる問題の対処
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
    top: 'auto',
    left: 'auto',
    right: '20px',
    bottom: '20px',
    zIndex: 1,
  },
}));

export default function GoUp() {
  const classes = useStyles();

  return (
    <Link to='pageTop' smooth={true} offset={-200}>
      <Fab size='medium' className={classes.fab}>
        <UpIcon />
      </Fab>
    </Link>
  );
}