import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import OpaqueAppbar from './OpaqueAppbar_en';
import GoUp from './GoUp';
import Profile from './ProfileMaker_en';
import MainMessage from './MainMessage_en';
import Attorneies from './Attorneies_en';
import ServiceListMaker from './ServiceListMaker_en';
import Summary from './Summary_en';
import AccessList from './AccessList_en';
import Footer from './Footer_en';

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 700,
    },
  },
})

const menuItem = {
    fontFamily:'Noto Sans JP',
    fontSize: '20px',
    fontWeight: 700,
    color: 'black',
    marginTop: '50px',
    marginBottom: '0px',
  }

export default function English() {
  return(
    <React.Fragment>
      <OpaqueAppbar />
      <GoUp />
      <Container maxWidth='md' style={{minWidth:'350px'}}>
        <div id='pageTop'>
          <MainMessage />
        </div>

        <div id='profile'>
          <h1 style={menuItem}>Office Profile</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <Profile />
        </div>

        <div id='services'>
          <h1 style={menuItem}>Our Services</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <ServiceListMaker />
        </div>

        <div id='attoneies'>
          <h1 style={menuItem}>Professionals</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <ThemeProvider theme={theme}>
            <Attorneies />
          </ThemeProvider>
        </div>
        
        <div id='profiles'>
          <h1 style={menuItem}>About Us</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <Summary />
        </div>

        <div id='access'>
          <h1 style={menuItem}>Access/Contact Us</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <AccessList />
        </div>
      </Container>
      <Footer />
    </React.Fragment>
  )
}
