import React from 'react';
//import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'
import yoshida from './imgs/yoshida.png';
import fukushima from './imgs/fukushima.png';
import fuyuki from './imgs/fuyuki.png'

const useStyles = makeStyles({
  paper: {
    marginTop:'40px',
    backgroundColor:'rgba(250,250,250,0.7)',
  },

  gridContainer: {
    padding: '20px 30px 30px 30px',
  },

  name: {
    fontFamily: 'Noto Sans JP',
    fontWeight: 700,
    textAlign: 'left',
    marginTop: '50px',
  },

  picture: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent:'center',
    alignItems:'center',
  },

  portrait: {
    maxWidth:'170px',
    width:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
    height:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
    marginTop: '10px',
    marginBottom: '10px',
  },
  
  appeal: {
    paddingLeft: '10px',
    borderLeftStyle : 'solid',
    borderLeftColor: 'rgba(0,8,95,1)',
    borderLeftWidth: '5.5px',
  },

  appealTitle: {
    fontFamily: 'Noto Serif JP',
    color: 'black',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontSize: '17px',
  },

  titleUnderline: {
    borderBottomStyle : 'solid',
    borderBottomColor: 'rgba(0,8,95,1)',
    borderBottomWidth: '1px',
  },

  appealDesc: {
    fontFamily: 'Noto Serif JP',
    fontSize: '15px',
    color: 'black',
    marginTop: '2%',
  },

  career: {
    fontSize: '15px',
    fontFamily: 'Noto Sans JP',
    fontWeight: 400,
    color: 'black',
    textAlign: 'left',
  },
});

export default function Attorneies() {
  const classes = useStyles();

  return (
      <Paper elevation={5} className={classes.paper}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={12} >
            <Box className={classes.appeal}>
              <Box className={classes.appealTitle}>
                  <span className={classes.titleUnderline}>WE ARE HERE FOR YOU</span>
              </Box>
              <Box className={classes.appealDesc}>
                <>
                  <span>All our professionals have broad technical knowledge and sufficient experience in the fields of Machinery, Electronics, Software / IT, and Organic / Inorganic Chemistry. We unite our efforts to serve you who desire intellectual property rights in Japan.</span><br/><br/>
                  <span>We will advise on rational and effective strategy to you, and lead your application to meaningful rights in Japan. We believe our quality, swiftness, and flexibility would satisfy your demands. In case the service fee is preventing you from obtaining intellectual property rights in Japan, please contact us, we will do our best for managing the problem to the utmost.</span>
                </>
              </Box>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={12}>
            <Grid item xs={12} sm={4} className={classes.picture}>
              <Box className={classes.name}>
                <span style={{fontSize:'15px'}}>Patent Attorney (Chief)</span><br/>
                <span style={{fontSize:'20px', whiteSpace:'nowrap'}}>Motoharu YOSHIDA</span>
              </Box>
              <img src={yoshida} className={classes.portrait} alt='吉田'/>
              <Box className={classes.career}>
                <span style={{fontSize:'15px'}}>excellent in</span><br/>
                <span style={{fontSize:'16px'}}>Machinery, Electronics, etc.</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.picture}>
              <Box className={classes.name}>
                <span style={{fontSize:'15px'}}>Patent Attorney</span><br/>
                <span style={{fontSize:'20px', whiteSpace:'nowrap'}}>Toru FUKUSHIMA</span>
              </Box>
              <img src={fukushima} className={classes.portrait} alt='福嶋'/>
              <Box className={classes.career}>
                <span style={{fontSize:'15px'}}>excellent in</span><br/>
                <span style={{fontSize:'16px'}}>Software, IT, etc.</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} className={classes.picture}>
              <Box className={classes.name}>
                <span style={{fontSize:'15px'}}>Patent Attorney</span><br/>
                <span style={{fontSize:'20px', whiteSpace:'nowrap'}}>Ikuyo FUYUKI</span>
              </Box>
              <img src={fuyuki} className={classes.portrait} alt='冬木'/>
              <Box className={classes.career}>
                <span style={{fontSize:'15px'}}>excellent in</span><br/>
                <span style={{fontSize:'16px'}}>Material, Chemistry, etc.</span>
              </Box>
            </Grid>
          </Grid>  
        </Grid>
      </Paper>
  );
}
