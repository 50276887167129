import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Japanese from './Japanese';
import English from './English';
import CC from './CC'

class App extends Component {
  render(){    
    return (
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route exact path="/" element={<Japanese />}/>
          <Route path="/en" element={<English />}/>
          {/* <Route path="/cc" element={<CC />}/> */}
          <Route path="*" element={<Japanese />}/>
        </Routes>        
      </BrowserRouter>
    )
  }
}
export default App