import React from 'react';

const tableStyle = {
  marginTop:'30px',
  borderCollapse: 'collapse',
  width:'100%',
};

const tdSytle = {  
  borderStyle: 'solid',
  borderWidth: '0.1px',
  borderColor: 'black',
  fontFamily: 'Noto Sans JP',
  fontWeight: 400,
  fontSize: '14px',
  padding: '10px 5px 10px 5px',
  align: 'left',
};

function createData(item, contents) {
  return { item, contents};
}

const rows = [
  createData('名称', '弁理士法人上野特許事務所'),
  createData('英文名称', 'WENO & PARTNERS'),
  createData('所在地', '愛知県名古屋市中区栄3丁目21番23号　KSイセヤビル8階'),
  createData('代表者', '吉田　元治'),
  createData('創業', '1991年（平成3年）4月'),
  createData('法人設立', '2015年（平成27年）5月'),
  createData('電話番号', '052-263-6871（代表）'),
  createData('ＦＡＸ', '052-263-6873'),
  createData('営業時間', (<>平日9:00～17:30　<span style={{whiteSpace:'nowrap'}}>※金曜日は16:30受付終了</span><br/></>)),
  createData('定休日', '土日祝日'),
  createData('提携法律事務所', (<>弁護士法人PRO（<a href='https://i-l.info/' target='blank'>https://i-l.info/</a>）</>)),
];

export default function Summary() {
  return (
    <table style={tableStyle}>
      <tbody>
        {rows.map((row) => (
          <tr>
            {//↓スプレッド構文（...）でtdSytleオブジェクトの内容を{{}}内に展開している
            }
            <td style={{...tdSytle, whiteSpace: 'nowrap'}}>{row.item}</td>
            <td style={tdSytle}>{row.contents}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
