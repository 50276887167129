import React, { Component } from 'react'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

const msgBox = {
  marginTop:'200px', 
  marginBottom:'200px',　
  display:'flex', 
  flexDirection:'column', 
  justifyContent:'center',
  textAlign: 'center',
  whiteSpace:'normal',
};

class MainMessage extends Component {
  constructor(props){
    super(props);
    this.state = {
      mainMsgSize: '0px',
      subMsgSize: '23px',
    }
    window.addEventListener('resize', () => this.setState(this.calcSize()))
    this.calcSize = this.calcSize.bind(this);
  }

  componentDidMount(){
    this.setState(this.calcSize());
  }

  calcSize(){
    let r = document.getElementById('root');
    let w = r.clientWidth;
    let s = w * 0.072;
  
    if(s >= 50){
      return({
        mainMsgSize: '50px',
      })
    }else if(s <= 35){
      return({
        mainMsgSize: '35px',
      })
    }else{
      return({
        mainMsgSize: s+'px',
      })
    }
  }

  /**ステートでフェードインフェードアウト　要素を消すunmountOnExit　https://penpen-dev.com/blog/react-transition-group/ */
  render(){
    return <div>
      <Fade in='true' timeout={5000}>
        <Box style={msgBox}>
          <Typography style={{fontFamily:'Noto Serif JP', fontSize:this.state.mainMsgSize}}>真に価値ある知財を</Typography>
        </Box>
      </Fade>
    </div>
  }
}

export default MainMessage
