import React from 'react';
import { Link as Rtlink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
//import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from './imgs/logo.svg'; //IE対応。こうしないとSVGロゴが表示できない。
import { ReactComponent as LogoText } from './imgs/logotext.svg'; //IE対応。こうしないとSVGロゴが表示できない。
import { ReactComponent as LogoTextFolded } from './imgs/logotext_folded.svg'; //IE対応。こうしないとSVGロゴが表示できない。


const useStyles = makeStyles({
  appBar: {
    color: 'black',
    backgroundColor: 'rgba(250,250,250,0.9)',
  },

  logoText: {
    maxWidth:'250px',
    width:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
    height:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
  },

  wenoMain: {
    fontFamily: 'Noto Sans JP',
    fontSize: '17px',
    fontWeight: 500,
    color: 'black',
  },

  wenoSub: {
    fontFamily: 'Noto Sans JP',
    fontSize: '13px',
    fontWeight: 400,
    color: 'black',
    paddingLeft: '3px',
  },

  vAlign: {
    display: 'flex',
    justifyContent:'space-between', //IE対応かも
    alignItems: 'center', //IE対応かも
    height: '50px', //IE対応。これがないと上下中央揃えにならない。
  },

  links :{
    fontFamily:'Noto Sans JP',
    fontWeight: 500,
    fontSize:'14px',
    margin:'0% 0% 0% 0%',
    textTransform: 'none',
  },

  phoneIcon: {
    display:'flex',
    alignItems:'center',
  },

  phoneNum: {
    fontFamily:'Noto Serif JP',
    fontWeight: 500,
    fontSize:'18px',
    margin:'0px 0px 0px 5px',
  },

  switchLang: {
    fontFamily: 'Noto Sans JP',
    fontWeight: 400,
    fontSize: '14px',
    color: '#3f51b5',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
  },
});

export default function OpaqueAppbar(props) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 900px)', {noSsr: true})

  return (
    <AppBar className={classes.appBar}>
      <Container style={{minWidth:'350px'}}>
        <Toolbar className={classes.vAlign} disableGutters={true}>

          <Logo style={{width:'11%', maxWidth:'40px'}} />

          {isMobile ?
            <LogoTextFolded style={{marginLeft:'10px', maxWidth:'155px'}}/>
          :
            <LogoText style={{marginLeft:'10px', maxWidth:'250px'}}/>         
          }
          
          <div style={{ flexGrow: 1 }}></div>

          <Rtlink to="/en">
            <Button style={{textTransform:'none'}}>
              <Typography className={classes.switchLang}>GO BACK</Typography>
            </Button>
          </Rtlink>

          {/*
            <div className={classes.phoneIcon}>
              <PhoneIcon style={{fontSize:'large'}}/>
            </div>
            <div className={classes.phoneNum}>
              052-263-6871
            </div>
          */}

        </Toolbar>
      </Container>
    </AppBar>
  );
}