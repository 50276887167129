import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceTemplate from './ServiceTemplate'
import consulting from './imgs/consulting.gif';
import patent from './imgs/patent.gif';
import trademark from './imgs/trademark.gif';
import design from './imgs/design.gif'
import intl from './imgs/intl.gif';
import trouble from './imgs/trouble.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '30px',
  },
}));

const consulDesc = () => {
  return (
        <>
          <span>「知的財産（知財）」とは、いわゆるアイデアや、ブランドの信用、デザインの美観、創作的な思想・感情表現、情報など、物質としての形はないものの、その観念・概念に財産的な価値が認められるものをいいます。そして、この知的財産を自らのものとする権利のことを「知的財産権」とよんでいます。</span><br/>
          <span>知的財産権の種類には、特許権、実用新案権、意匠権、商標権、いわゆる著作権、種苗（しゅびょう）の新品種の育成者権、半導体集積回路の回路配置利用権などがあります。このうち、特許権、実用新案権、意匠権、及び商標権の四つを特に「産業財産権」といい、これらは、特許庁に出願をして審査に合格したものにのみその権利が認められます。これら権利の詳細については各サービスの説明をご覧下さい。</span><br/>
          <span>弊所は、特許・商標をはじめとする知的財産権制度についてのレクチャーから、合理的な知財戦略のご提案、日々の知財活動の手ほどきまで、ご要望に応じて広く柔軟にサポート致します。</span><br/>
          <span>知的財産が自社の事業と無関係でないことは薄々分かっていながらも、 その得体の知れなさや費用のご心配から二の足を踏んでいらっしゃる方も多いと思います。知的財産権は、オリジナルがオリジナルとして正当な評価や利益を得るために不可欠なものです。一方で、知財戦略には絶対的な正解が存在しないこともまた事実です。弊所にご相談頂ければ、貴社にとっての最適解を、それが見つかるまで共にお探し致します。</span><br/>
        </>
  );
};

const patentDesc = () => {
  return (
        <>
          <span>特許とは、試行錯誤や創意工夫により生み出された技術的なアイデアである「発明」を、一定期間（出願日から20年間）、独占的に利用することができる権利です。</span><br/>
          <span>特許される発明は何も革新的なアイデアだけには限られません。小さな工夫であっても、これまでに知られておらず、それなりの技術的意義が伴うものであれば特許を狙うことはできます。</span><br/>
          <span>弊所は、貴社のアイデア一つ一つと真摯に向き合い、そのアイデアの真価を引き出す特許を目指します。単なる出願代行には留まらず、そのアイデアを特許出願すべきかどうかの調査・アドバイスや、審査の過程を見通した出願計画のご提案など、出願の前段階から最終処分に至るまで、ご不安のないよう丁寧にリーディング致します。</span><br/>
          <span>社内に知的財産部門をお持ちのお客様など、特許活動のプロセスがすでに確立されているお客様に対しては、迅速かつ高品質なサービスをご提供することはもちろんのこと、継続的な改善を通して、貴社の業務負担の軽減や業務効率の向上を図って参ります。</span><br/>
          <span>ちなみに、実用新案も特許と同じく技術的アイデアを保護する制度ですが、実用新案はその出願段階では権利の有効性が完全には審査されず、実際に権利行使を検討する段階までその権利が有効なのかどうか分からないという扱いにくさがあります。また、特許よりも権利の存続期間が短い（出願日から10年間）という欠点もあります。そのため弊所では、アイデアの保護手段として実用新案登録を選択することは積極的にはお薦めしておりません。ただ、弊所のお客様の中には、実用新案のそのグレーな牽制効果をうまく利用して他社による模倣を防いでおられる方もいらっしゃいます。要は使い方次第ということになろうかと思います。</span><br/>
                  </>
  );
};

const trademarkDesc = () => {
  return (
        <>
          <span>商標は、貴社の事業活動に対する信用や評価が乗り移る「依代（よりしろ）」です。商標の種類には、例えば社名、製品名・サービス名、ロゴマーク、マスコットキャラクター、コーポレートカラー、メロディ・音声などがあります。特許庁の審査を経て登録に至った商標を特別に「登録商標」といいます。よく目にする<span style={{fontFamily:'Roboto, -apple-system, BlinkMacSystemFont, sans-serif'}}>®</span>という記号はその商標が登録商標であることを意味しています。</span><br/>
          <span>適切に育てられた商標はやがて「ブランド」に昇華し、強力な営業ツールとなります。そのため、商標の名称やロゴデザイン等は、名声が効率良く蓄積されていくよう、識別しやすく、記憶に残りやすいものを選ぶべきです。</span><br/>
          <span>また、商標を登録する際には、その商標を使用する商品やサービスを一覧の中から選択する必要があります。 この選択を誤ると、権利の内容が貴社の事業内容と乖離したものになってしまい、いざとなったときに慌てることになります。この商品・サービスの選択には注意点や落とし穴が多く、一覧にある商品名やサービス名の字面だけに頼ってその要否を判断することは危険です。</span><br/>
          <span>弊所に商標登録をお任せ頂ければ、ご検討中の名称やロゴデザイン等の商標としての適性についても、一覧からの商品・サービスの選び方についても、後で困ることのないよう丁寧にアドバイス致します。</span><br/>
        </>
  );
};

const designDesc = () => {
  return (
        <>
          <span>知的財産の文脈で「意匠」というときは、量産可能な製品のデザイン（いわゆるインダストリアルデザイン）のことを指しており、意匠権とは、そのデザインと同一または類似するデザインを一定期間（出願日から25年間）、独占的に利用することができる権利です。</span><br/>
          <span>デザインを保護する意匠には、特許と商標の中性的な役割もあります。意匠は、製品の使い勝手や機能を左右することがあり、また、ブランディングに大きく関わることもあります。</span><br/>
          <span>優れたデザインは製品に付加価値を与え、ときに価格差や性能差さえも凌駕することがあります。特に B to C 市場における製品デザインの重要性は想像に難くないと思います。</span><br/>
          <span>各社がデザイン開発に躍起になっている中で真に魅力的なデザインを創作することは、言うなれば百花繚乱の花畑の中でも存在感を放つ花を生み出すことであり、容易なことではありません。一方、製品の外観に表れるデザインは、それを目にしてしまえば簡単に模倣することができます。成功を収めたデザインが丸腰のままだと、それは模倣者にとって格好の餌食となります。</span><br/>
          <span>意匠権は貴社が苦労の末たどり着いたデザインを貴社のものと認めさせる権利です。意匠権は、貴社の長期的なデザイン戦略の礎となり、市場において貴社がそのデザインとともに進化する「デザイン経営（是非ご検索下さい）」を実現する強力な手段です。</span><br/>
          <span>意匠法は令和元年に大改正が行われ、より時代にマッチした制度に生まれ変わりました。例えばこれまで意匠法では保護されなかった、ウェブサイトやアプリのユーザーインタフェースのデザイン（画像デザイン）、店舗の内装・外装デザインなどが新たに保護対象に加わりました。また、「関連意匠」といって、一つのデザインやそのデザインコンセプトを一まとまりの複数の意匠権で保護する制度があるのですが、その制度が強化されています。新たな関連意匠制度では、権利の中心に据える意匠の出願日からその後10年間、その類似範囲、類似範囲へと、必要に応じて連鎖的に権利範囲を拡張していくことができます。つまり、時代や流行の変化に伴うデザインの変更に合わせて権利範囲も変化させることができるということです。</span><br/>
          <span>改正意匠法の力はまだ未知数な部分もありますが、弊所にご相談頂ければ、改正意匠法のあらゆるツールを総動員して貴社のデザイン戦略をサポートさせて頂きます。</span><br/>
        </>
  );
};

const intlDesc = () => {
  return (
        <>
          <span>知的財産の外国における権利化も弊所の得意とするところです。</span><br/>
          <span>弊所は、欧米・中国をはじめ諸外国の特許事務所・法律事務所と提携しており、貴社の事業規模に応じて、わが国のみならず海外へも知財活動を展開することができます。貴社と各国の現地代理人との間には弊所が立ち、弊所が一元的な渉外窓口を務めますので、貴社は弊所にのみご要望をお伝え下されば結構です。</span><br/>
          <span>外国出願は、日本国内の出願に比べて費用が膨らみやすく、中小企業にとってはなかなか手が出しにくいものです。弊所には、失敗に終わる外国出願を減らし、国や自治体の補助金を有効に活用できるノウハウがあります。弊所に外国出願をお任せ頂ければ、限られた外国出願予算の効果を最大限に引き出します。</span><br/>
       </>
  );
};

const troubleDesc = () => {
  return (
        <>
          <span>知的財産権の取得は、目的ではなく手段です。知財を武器に市場における地位を確立していく過程では、知的財産権の侵害・被侵害など、知財を巡る争いに巻き込まれることもあります。</span><br/>
          <span>知的財産権は様々な権利の中でも特に強力な権利です。知的財産権制度には、権利者の利益を適切に保護するための規定が種々用意されています。また、特許権や商標権など、特許庁の審査を経て登録された権利は、その権利の存在や、帰属（権利者）、権利範囲について予め国からのお墨付きが与えられています。このこともあり、知財を巡る争いはその優勢・劣勢を比較的判断しやすく、特に日本においては、訴訟に至らず話し合いで解決することも多くあります。</span><br/>
          <span>とはいえ、紛争を平和的に解決できるかどうかは相手の出方次第です。お互いに引くに引けない状況に陥ったときには司法の判断を仰がざるをえません。弊所は、知財の争いに長けた法律事務所と密に提携しており、いざとなったときには、弊所の弁理士と信頼できる弁護士とがタッグを組んで紛争の解決に臨みます。</span><br/>
        </>
  );
};

export default function ServiceListMaker() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <ServiceTemplate
              picture={consulting}
              title='知的財産コンサルティング'
              desc = {consulDesc()}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={patent}
              title='特許出願'
              desc = {patentDesc()}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={trademark}
              title='商標登録出願'
              desc = {trademarkDesc()}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={design}
              title='意匠登録出願'
              desc = {designDesc()}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={intl}
              title='国際出願・外国出願'
              desc = {intlDesc()}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={trouble}
              title='知的財産トラブル対応'
              desc = {troubleDesc()}
            />
        </Grid>
      </Grid>
    </div>
  );
}
