import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceTemplate from './ServiceTemplate_en'
import patent from './imgs/patent.gif';
import trouble from './imgs/trouble.gif';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '30px',
  },
}));

const patentDesc = () => {
  return (
    <>
    <span>We provide comprehensive IP prosecution sevice of Patent, Utility Model, Design and Trademark in Japan, namely, application filing, trial, opposition, appraisal, search, translation, license agreement and any others related to intellectual properties.</span><br/><br/>
    <span>All our professionals used to be an engineer or a researcher in a variety of areas.   We, our collective intelligence, have broad technical knowledge and sufficient experience in the fields of Machinery, Electronics, Software / IT, and Organic / Inorganic Chemistry.</span><br/><br/>
    <span>We offer our excellent services at reasonable cost.  We have been striving to cut out inefficient expense to reduce service fee.  Please feel free to contact us for our schedule of charges.   We do try our best for our / your client not to give up obtaining sufficient rights in Japan due to the expensive fee. </span>
   </>
  );
};

const troubleDesc = () => {
  return (
    <>
      <span>In Japan, patent attorney and lawyer are different qualifications, and their roles in infringement lawsuits are also different. Patent attorney can conduct trials (appeals) and lawsuits against trial decisions, but patent attorney is not allowed to conduct an infringement lawsuit alone. Patent attorney must undertake it together with a lawyer.</span><br/><br/>
      <span>In infringement lawsuits, patent attorney supports lawyer in understanding technological aspects of the subject-matter and points of the case, and the lawyer elaborates litigation strategy based on it.</span><br/><br/>
      <span>Therefore, it is very important to find a good lawyer for winning the case. However, lawyers, who have sufficient knowledge and experience on intellectual property, are not many. We are affiliated with legal professional corporation PRO whose lawyers have excellent legal skill in intellectual property lawsuit. When an infringement occurred, we work in strong collaboration with those lawyers for your desired goal.</span>
    </>
  );
};

export default function ServiceListMaker() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={patent}
              title='IP Prosecution'
              desc = {patentDesc()}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
        <ServiceTemplate
              picture={trouble}
              title='IP Litigation'
              desc = {troubleDesc()}
            />
        </Grid>
      </Grid>
    </div>
  );
}
