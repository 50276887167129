import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import footerlogo from './imgs/footerlogo.png'

const useStyles = makeStyles({
    footerColor: {
        backgroundColor: 'rgba(0,26,90,1)',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '120px',
        marginTop: '30px'
    },

    text: {
        color:'white',
        fontFamily: 'Noto Sans JP',
        fontWeight: 300,
        fontSize: '12px',
    },

    logo: {
        maxWidth:'350px',
        width:'90%', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
        height:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
      },
  });

export default function Footer() {
    const classes = useStyles();
    return (
    <div className={classes.footerColor}>
        <Container className={classes.container}>
            <img src={footerlogo} className={classes.logo} alt='logo'/>
            <Typography className={classes.text}><span style={{fontFamily:'Roboto, -apple-system, BlinkMacSystemFont, sans-serif'}}>©</span>2021 WENO & PARTNERS</Typography>
        </Container>
    </div>
    );
}
