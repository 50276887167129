import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ServiceTemplate from './ProfileTemplate_en'
import nagoya from './imgs/nagoya_cut.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '30px',
  },
}));

const nagoyaDesc = () => {
  return (
    <>
        <span>WENO & PARTNERS was founded in 1991 in Nagoya city: one of the major cities in Japan. Nagoya and neighboring cities (cities in Chubu area) are the heart of commerce and industry in Japan, especially in fields of automobile, ceramics, textile, machine tools and electronics.</span><br/><br/>
        <span>Importance of intellectual property rights is so common for the companies in this area as to be a general knowledge. They have very high motivation for obtaining good intellectual properties. We have been continuing KAIZEN (improvement activity) to meet our clients' high demands for more than 30 years. Our professionals and clerks have various expertise and long-term experience. We, our team, provide you with comprehensive and meticulous services for your success in business.</span><br/><br/>
        <span>We have been offering our services mainly in this area and oversea clients. Most of our professionals and clerks are bilingual (Japanese and English), and we are in partnership with reliable law firms in various countries including those in America, Europe, and Asia. Foreign clients' application into Japan is one of our specialties. We believe our strongest feature is rejection handling. We offer our excellent services at reasonable cost. For reaching the best result, we recommend leaving its translation to us too.</span>
    </>
  );
};

export default function ServiceListMaker() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}>
        <ServiceTemplate
              picture={nagoya}
              desc = {nagoyaDesc()}
            />
        </Grid>
      </Grid>
    </div>
  );
}
