import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import OpaqueAppbar from './OpaqueAppbar'
import GoUp from './GoUp'
import MainMessage from './MainMessage';
import Attorneies from './Attorneies';
import ServiceListMaker from './ServiceListMaker';
import Summary from './Summary';
import AccessList from './AccessList';
import Footer from './Footer';
//import Inquiry from './ContactUs';

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 700,
    },
  },
})

const menuItem = {
    fontFamily:'Noto Sans JP',
    fontSize: '20px',
    fontWeight: 700,
    color: 'black',
    marginTop: '50px',
    marginBottom: '0px',
  }

export default function Japanese() {
  return(
    <React.Fragment>
      <OpaqueAppbar />
      <GoUp />
      <Container maxWidth='md' style={{minWidth:'350px'}}>
        <div id='pageTop'>
          <MainMessage />
        </div>

        <div id='services'>
          <h1 style={menuItem}>サービスメニュー</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <ServiceListMaker />
        </div>

        <div id='attoneies'>
          <h1 style={menuItem}>所属弁理士</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <ThemeProvider theme={theme}>
            <Attorneies />
          </ThemeProvider>
        </div>
        
        <div id='profiles'>
          <h1 style={menuItem}>事務所概要</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <Summary />
        </div>

        <div id='access'>
          <h1 style={menuItem}>アクセス・お問い合わせ</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <AccessList />
        </div>

{/*
          <div id='inquiry'>
          <h1 style={menuItem}>お問い合わせ</h1>
          <Divider style={{backgroundColor:'black'}}/>
          <Inquiry />
        </div>
*/}
      </Container>
      <Footer />
    </React.Fragment>
  )
}
