import React from 'react';
//import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'
import yoshida from './imgs/yoshida.png';
import fukushima from './imgs/fukushima.png';
import fuyuki from './imgs/fuyuki.png'

const useStyles = makeStyles({
  paper: {
    marginTop:'40px',
    backgroundColor:'rgba(250,250,250,0.7)',
  },

  gridContainer: {
    padding: '30px 30px 30px 24px',
  },

  name: {
    fontFamily: 'Noto Sans JP',
    fontWeight: 700,
    textAlign: 'left',
    paddingLeft: "10px",
  },

  picture: {
    display: 'flex',
    justifyContent:'center',
    alignItems:'flex-end',
  },

  portrait: {
    maxWidth:'170px',
    width:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
    height:'auto', //IEではこれを指定しておかないとウィンドウを狭めたときに画像の縦横比が乱れる
  },
  
  appeal: {
    paddingLeft: '10px',
    marginTop: '25px',
    borderLeftStyle : 'solid',
    borderLeftColor: 'rgba(0,8,95,1)',
    borderLeftWidth: '5px',
  },

  appealTitle: {
    fontFamily: 'Noto Serif JP',
    color: 'black',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    fontSize: '17px',
  },

  titleUnderline: {
    borderBottomStyle : 'solid',
    borderBottomColor: 'rgba(0,8,95,1)',
    borderBottomWidth: '1px',
  },

  appealDesc: {
    fontFamily: 'Noto Serif JP',
    fontSize: '15px',
    color: 'black',
    marginTop: '2%',
  },

  career: {
    fontSize: '15px',
    fontFamily: 'Noto Sans JP',
    fontWeight: 400,
    color: 'black',
    textAlign: 'left',
    marginTop: '25px',
    width: '100%',
  },
});

export default function Attorneies() {
  const classes = useStyles();

  /*
  const elm = useRef(null);
  const [size, setSize] = useState(20);
  const calcSize = () => {
    if(elm.current){
      let e = elm.current.getBoundingClientRect();
      let w = e.width;
      let s = w * (100/100);
      console.log(e.width);
      if(s >= 20){
        setSize(20)
      }else if(s <= 18){
        setSize(18)
      }else{
        setSize(s)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', calcSize)
    return () => {
      window.removeEventListener('resize', calcSize)
    }
  });
  */

  return (
      <React.Fragment>
      <Paper elevation={5} className={classes.paper}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={9} >
            <Box className={classes.name}>
                <span style={{fontSize:'18px'}}>代表 </span>
                <span style={{fontSize:'25px'}}>吉田 元治</span>
                <span style={{fontSize:'20px', whiteSpace:'nowrap'}}> （Motoharu YOSHIDA）</span>
            </Box>
            <Box className={classes.appeal}>
              <Box className={classes.appealTitle}>
                  <span className={classes.titleUnderline}>取る選択も、取らない選択も。</span>
              </Box>
              <Box className={classes.appealDesc}>
                私ども特許事務所は、特許権や商標権など、知的財産権取得のお手伝いをすることが主な収入源です。それでも、お客様にとって不要だと思われるものについて、はっきりと「不要」であるとお伝えすることが、お客様との信頼関係構築に「必要」であると考えております。
                {//IEではソース内のテキストの改行が半角スペースに置き換えられて表示されてしまうから、一行で書かかざるを得ない
                }
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.picture}>
            <img src={yoshida} className={classes.portrait} alt='吉田'/>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={5} className={classes.paper}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={9} >
            <Box className={classes.name}>
              <span style={{fontSize:'18px'}}></span>
              <span style={{fontSize:'25px'}}>福嶋 亨</span>
              <span style={{fontSize:'20px', whiteSpace:'nowrap'}}> （Toru FUKUSHIMA）</span>
            </Box>
            <Box className={classes.appeal}>
              <Box className={classes.appealTitle}>
              <span className={classes.titleUnderline}>ご期待を上回ってこその存在意義</span>
              </Box>
              <Box className={classes.appealDesc}>
                知財活動は、市場における貴社のテリトリーを創造力とブランディングで切り開いていく作業です。そして我々の役割は、お客様が創り出した知的財産を一社独占の権利につなげ、それを市場におけるお客様の個性として開花させることです。知的財産の権利化は一筋縄ではいかないことが常ですが、一歩でも、せめて半歩でも、ご期待を超える成果を収め続けることが、切なる思いのこもった知的財産をお預かりする者の責務であると考えております。
              </Box>
            </Box>

            <Grid container spacing={2} className={classes.career}>
              <Grid item xs={12} sm={2} style={{textAlign:'left', whiteSpace:'nowrap'}}>
                【担当技術】
              </Grid>
              <Grid item container xs={12} sm={10}>
                ソフトウェア，情報システム 等
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.picture}>
          <img src={fukushima} className={classes.portrait} alt='福嶋'/>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={5} className={classes.paper}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} sm={9} >
            <Box className={classes.name}>
              <span style={{fontSize:'18px'}}></span>
              <span style={{fontSize:'25px'}}>冬木 郁代</span>
              <span style={{fontSize:'20px', whiteSpace:'nowrap'}}> （Ikuyo FUYUKI）</span>
            </Box>
            <Box className={classes.appeal}>
              <Box className={classes.appealTitle}>
              <span className={classes.titleUnderline}>科学技術に向き合う者として</span>
              </Box>
              <Box className={classes.appealDesc}>
              特許法の最初に、発明とは「自然法則を利用した技術的思想の創作のうち高度のもの」と規定されている以上、弁理士はお客様の利益と同じくら
い、自然科学を尊重すべきであると考えています。<br />
科学技術への敬意を第一に、発明者様の英知と熱意を特許明細書に込め、特許権につなげられるよう、努力いたします。
              </Box>
            </Box>
            <Grid container spacing={2} className={classes.career}>
              <Grid item xs={12} sm={2} style={{textAlign:'left', whiteSpace:'nowrap'}}>
                【担当技術】
              </Grid>
              <Grid item container xs={12} sm={10}>
                材料，化学 等
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.picture}>
            <img src={fuyuki} className={classes.portrait} alt='冬木'/>
          </Grid>
        </Grid>
      </Paper>
      </React.Fragment>
  );
}
