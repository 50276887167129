import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  serviceTitle: {
    fontFamily: 'Noto Sans JP',
    fontWeight: 500,
    fontSize: '19px',
    textAlign: 'left',
    paddingLeft: '10px',
    color: 'black',
    whiteSpace:'nowrap',
  },
  titleBox: {
    height:'30px',
    marginBottom: '20px',
    borderLeftStyle : 'solid',
    borderLeftColor: 'rgba(0,8,95,1)',
    borderLeftWidth: '5.5px',
    borderBottomStyle : 'solid',
    borderBottomColor: 'rgba(0,8,95,1)',
    borderBottomWidth: '1px',
  },
  buttonPosition: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  expansionButton: {
    fontFamily: 'Noto Sans JP',
    fontWeight: 400,
    fontSize: '15px',
  },
}));

export default function ServiceTemplate(props) {
  const classes = useStyles();

  const whenCollapsed = {
    color: 'black',
    fontFamily: 'Noto Serif JP',
    fontWeight: 500,
    fontSize: '15px',
    textAlign: 'justify',
  }
  
  const whenExpanded = {
    color: 'black',
    fontFamily: 'Noto Serif JP',
    fontWeight: 500,
    fontSize: '15px',
    textAlign: 'justify',
    backgroundImage: `url(${props.picture})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  }

  const [checked, setChecked] = React.useState(false);
  const [caption, setCaption] = React.useState('...続きを読む ▼');
  const [style, setStyle] = React.useState(whenCollapsed);
  const handleChange = () => {
    setChecked((prev) => !prev);
    setCaption((prev) => checked? '...続きを読む ▼' : '折りたたむ ▲');
    setStyle((prev) => checked? whenCollapsed : whenExpanded);
  };

  return (
    <Paper elevation={5} style={{padding:'25px 25px 0px 25px', backgroundColor:'rgba(250,250,250,0.6)'}}>
      <Box className={classes.titleBox}>
        <Typography paragraph className={classes.serviceTitle}>{props.title}</Typography>
      </Box>
      <Collapse style={style} in={checked} collapsedHeight={150}>
        {props.desc}
      </Collapse>

      <Box className={classes.buttonPosition} >
        <FormControlLabel
          control={
            <Button className={classes.expansionButton} color='primary' onClick={handleChange}>
              {caption}
            </Button>
          }
        />
      </Box>
    </Paper>
  );
}
