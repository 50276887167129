import React from 'react'
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';
import WenoMap from './WenoMap';

const listStyle = {
  marginTop: '30px',
  padding: '0%',
}

const itemStyle = {
  padding: '0%',
  margin: '10px',
}

const iconStyle = {
  color:'black',
}

const textStyle = {
  fontFamily: 'Noto Sans JP',
  fontSize: '15px',
  fontWeight: 400,
  color: 'black',
}

export default function AccessList() {
  return( 
  <React.Fragment>
    <List style={listStyle}> 
      <ListItem  style={itemStyle}>
        <ListItemIcon>
          <PhoneIcon style={iconStyle}/>
        </ListItemIcon>
        <ListItemText disableTypography style={textStyle}>
          +81-52-263-6871（Email is preferable if not urgent）
        </ListItemText>
      </ListItem>

      <ListItem  style={itemStyle}>
        <ListItemIcon>
          <EmailIcon  style={iconStyle}/>
        </ListItemIcon>
        <ListItemText disableTypography style={textStyle}>
          office(a)weno.jp
          <span>（Please replace (a) with @ when use）</span>
        </ListItemText>
      </ListItem>

      <ListItem  style={itemStyle}>
        <ListItemIcon>
          <MapIcon  style={iconStyle}/>
        </ListItemIcon>
        <ListItemText disableTypography style={textStyle}>
        KS Iseya Building 8th floor, 3-21-23 Sakae, Naka-ku, Nagoya-shi, Aichi-ken, 460-0008 Japan
        </ListItemText>
      </ListItem>
    </List>

    <Box display='flex' justifyContent='center'>
      <WenoMap />
    </Box>
  </React.Fragment>
  )
}
