import React from 'react';
import { GoogleMap, LoadScript, Marker, } from "@react-google-maps/api";

const containerStyle = {
    height: "54vh",
    width: "100%",
};

const wenoLocation = {
    lat: 35.1645274470877, 
    lng: 136.90270688872633,
};

export default function WenoMap() {
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyCDJSh7R7PcR4ZL8N4Buhk76yHKUCY1cvQ"
            language='en'
            region='JP'
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={wenoLocation}
                zoom={15}
                options={{
                    streetViewControl: false,
                    scaleControl: false,
                    mapTypeControl: false,
                    panControl: false,
                    zoomControl: true,
                    rotateControl: false,
                    fullscreenControl: false,
                  }}
            >
                <Marker position={wenoLocation} />
            </GoogleMap>
        </LoadScript>
    )
}